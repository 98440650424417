import React, { useState } from "react";
import "./Header.scss";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const Header = () => {
	const [toggleCheckBox, setToggleCheckBox] = useState(false);

	const menuHandler = () => {
		setToggleCheckBox(!toggleCheckBox);
	};

	const menuList = [
		{ page: "home" },
		{ page: "journey" },
		{ page: "work" },
		{ page: "connect" },
	];

	return (
		<header className='header'>
			<input
				type='checkbox'
				className='h-menu__toggler'
				onChange={menuHandler}
				checked={toggleCheckBox}
			/>
			<div className='h-menu'>
				<div className='h-menu__lines'></div>
			</div>
			<nav className='menu'>
				<ul className='menu__list'>
					{menuList.map(item => {
						const { page } = item;
						return (
							<li
								className={`menu__list-item menu__list-item--${page}`}
								onClick={() => setToggleCheckBox(false)}
								key={uuidv4()}
							>
								<NavLink
									exact
									to={page === "home" ? "/" : `/${page}`}
									className='menu__link
								'
									activeClassName='menu__link--selected'
								>
									{page}
								</NavLink>
							</li>
						);
					})}
				</ul>
			</nav>
		</header>
	);
};

export default Header;
