import React, { useState } from "react";
import hero from "../../assets/images/Krizzia1.JPG";
import loveHero from "../../assets/images/lovefababeir.JPG";
import HeroKrizziaText from "./HeroKrizziaText.js";
import HeroLoveText from "./HeroLoveText";

const HeroContent = ({ height }) => {
	const [description, setDescription] = useState("Krizzia");
	const [showText, setShowText] = useState("greeting");

	const heroContentStyle = {
		width: "100%",
		height: "100%",
		position: "absolute",
		left: 0,
		top: 0,
	};
	return (
		<div
			className='hero'
			style={{
				...heroContentStyle,
			}}
		>
			{description === "Krizzia" ? (
				<img className='hero__img' src={hero} alt='Krizzia Fababeir' />
			) : (
				<img
					className='hero__img hero__img--love'
					src={loveHero}
					alt='Krizzia Fababeir'
				/>
			)}
			{description === "Krizzia" && (
				<HeroKrizziaText
					setShowText={setShowText}
					showText={showText}
					setDescription={setDescription}
				/>
			)}
			{description === "Love" && (
				<HeroLoveText
					setShowText={setShowText}
					showText={showText}
					height={height}
				/>
			)}
		</div>
	);
};

export default HeroContent;
