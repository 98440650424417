import React from "react";
import "../Journey/Journey.scss";
import { eventsList } from "../Timeline/eventsList.js";
import Timeline from "../Timeline/Timeline.jsx";

const Journey = () => {
	return (
		<section className='journey'>
			<div className='journey__hero'>
				<h1 className='journey__title'> My Journey </h1>
			</div>
			<div className='timeline'>
				<div className='timeline__line'></div>
				{eventsList.map((e, i) => {
					return (
						<Timeline
							year={e.year}
							period={e.period}
							title={e.title}
							description={e.description}
							picture={e.image}
							key={i}
							index={i}
							institute={e.company}
						/>
					);
				})}
			</div>
			<div className='journey__footerBG' />
		</section>
	);
};

export default Journey;
