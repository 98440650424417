import React from "react";
import { v4 as uuidv4 } from "uuid";
import WorkItem from "../WorkItem/WorkItem.jsx";
import WorkItem2 from "../WorkItem2/WorkItem2.jsx";
import { workList } from "../WorkStack/workList.js";
import "./WorkStack.scss";

const WorkStack = ({
	selectedProject,
	showStack,
	setSelectedProject,
	setShowStack,
}) => {
	const totalWork = workList.length;

	return workList.map((project, i) => {
		return (
			<div
				className={`${
					i === selectedProject ? "project project--selected" : "project"
				}`}
				style={
					showStack
						? {
								transform: `${`translate3d(0px, 87%,${
									-120 - (650 / totalWork) * i
								}px )`}`,
								zIndex: `${totalWork - i}`,
								opacity: 0.98,
						  }
						: i === selectedProject
						? {
								transform: "translate3d(0px,-40px,0px)",
								zIndex: `${totalWork - i}`,
								opacity: 1,
						  }
						: {
								transform: "translate3d(0px, 200%,0px)",
								zIndex: `${totalWork - i}`,
								opacity: 0.98,
						  }
				}
				key={project.id}
			>
				{/* contents of card depending on if its a project or a challenge */}
				{project.type === "project" ? (
					<WorkItem
						title={project.title}
						description={project.description}
						tech={project.tech}
						images={project.images}
						openWork={setSelectedProject}
						setShowStack={setShowStack}
						showStack={showStack}
						type={project.type}
						demo={project.demo}
						code={project.code}
						app={project.link}
						wasSelected={selectedProject === i}
						i={i}
						key={uuidv4()}
					/>
				) : (
					<WorkItem2
						title={project.title}
						description={project.description}
						openWork={setSelectedProject}
						setShowStack={setShowStack}
						showStack={showStack}
						type={project.type}
						runtime={project.runtime}
						memory={project.memory}
						example={project.examples}
						link={project.link}
						code={project.code}
						wasSelected={selectedProject === i}
						i={i}
						key={uuidv4()}
					/>
				)}
			</div>
		);
	});
};

export default WorkStack;
