import React from "react";
import Typist from "react-typist";

const HeroKrizziaText = ({ setShowText, showText, setDescription }) => {
	return (
		<div className='hero__about'>
			<div className='hero__about-box'>
				<h1>
					<Typist
						cursor={{ show: true, blink: true, element: "_", hideWhenDone: true }}
						avgTypingDelay={70}
						onTypingDone={() => {
							setTimeout(() => {
								setShowText("name");
							}, 1000);
						}}
						className='hero__title hero__title--1 '
					>
						<Typist.Delay ms={2500} />
						Hey,
					</Typist>
				</h1>
				{(showText === "name" || showText === "description") && (
					<h1>
						<Typist
							cursor={{
								show: true,
								blink: true,
								element: "_",
								hideWhenDone: true,
							}}
							avgTypingDelay={50}
							onTypingDone={() => {
								setTimeout(() => {
									setShowText("description");
								}, 1000);
							}}
							className='hero__title'
						>
							I'm Krizzia
						</Typist>
					</h1>
				)}
				{showText === "description" && (
					<h4 className='hero__info'>
						<Typist
							cursor={{ show: true, blink: true, element: "_", hideWhenDone: true }}
							avgTypingDelay={40}
							onTypingDone={() => {
								setTimeout(() => {
									setDescription("Love");
									setShowText("");
								}, 2000);
							}}
						>
							<span className='profession'>Full Stack Web Developer. </span>
							Mathematics enthusiast. Critical thinker. Creative problem-solver.
							Self-Motivated. Driven. Independant. Flexible. Life-long Learner.
						</Typist>
					</h4>
				)}
			</div>
		</div>
	);
};

export default HeroKrizziaText;
