import React from "react";

const Ring = ({ radius, percentage, data, ringNum }) => {
	return (
		<div className={`percent percent${ringNum}`}>
			<svg className='percent__svg'>
				<circle
					cx={radius}
					cy={radius}
					r={radius}
					className='percent__circle percent__circle-grey'
				></circle>
				<circle
					cx={radius}
					cy={radius}
					r={radius}
					className={`percent__circle percent__circle-color percent__circle-color--${ringNum}`}
					style={{ strokeDashoffset: percentage }}
				></circle>
			</svg>
			<div className='ring__info'>
				<h2 className='ring__percent'>
					{data[1]}
					<span className={`ring__symbol-${ringNum}`}>%</span>
				</h2>
				<h2 className='ring__percent'>{data[0]}</h2>
			</div>
		</div>
	);
};

export default Ring;
