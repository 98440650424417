import React from "react";
import "../Home/Home.scss";
import Hero from "../Hero/Hero.js";
import Techstack from "../Techstack/Techstack.jsx";

const Home = () => {
	return (
		<section className='home'>
			<Hero />
			<Techstack />
		</section>
	);
};

export default Home;
