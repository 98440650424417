import React from "react";
import "../WorkItem2/WorkItem2.scss";
import PerformanceRing from "../PerformanceRing/PerformanceRing.jsx";
import LeetcodeIcon from "../../assets/images/leetcode.png";
import Leetcode from "../Leetcode/Leetcode.jsx";

const WorkItem2 = props => {
	const {
		title,
		description,
		openWork,
		setShowStack,
		showStack,
		type,
		memory,
		runtime,
		example,
		link,
		i,
		code,
		wasSelected,
	} = props;

	return (
		<>
			<h3
				className='project__title'
				style={wasSelected ? { opacity: 0 } : { opacity: 1 }}
				onClick={() => {
					openWork(i);
					setShowStack(false);
				}}
			>
				{type}
			</h3>
			<div className='challenge-card'>
				<h1 className='challenge-card__title'>
					{title}
					{wasSelected && (
						<a className='challenge-card__link' href={link} target='blank'>
							<img
								src={LeetcodeIcon}
								className='challenge-card__link-icon'
								alt='Click here to see the challenge'
							/>
						</a>
					)}
				</h1>
				<button
					className='challenge-content__close button__showStack'
					onClick={() => {
						openWork("");
						setShowStack(true);
					}}
					style={wasSelected ? { opacity: 1 } : { opacity: 0 }}
				>
					back to list
				</button>
				<div
					className='challenge-content'
					style={wasSelected ? { opacity: 1 } : { opacity: 0 }}
				>
					{/* ------Challenge details------ */}
					<div className='challenge-content__info'>
						<p className='challenge-content__description'>
							<span className='challenge-content__challenge'>Challenge:</span>{" "}
							{description}
						</p>
						{example && (
							<img
								src={example}
								alt='example of the solution'
								className='challenge-content__example'
							/>
						)}
					</div>
					<PerformanceRing runtime={runtime} memory={memory} />
				</div>
				{!showStack && <Leetcode code={code} />}
			</div>
		</>
	);
};
export default WorkItem2;
