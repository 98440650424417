import React, { useState, useEffect } from "react";
import "../PerformanceRing/PerformanceRing.scss";
import Ring from "./Ring.jsx";

const CodeRing = props => {
	const { runtime, memory } = props;

	//media query breakpoint for inline styles for the rings
	const [width, setWidth] = useState(window.innerWidth);
	useEffect(() => {
		window.addEventListener("resize", updateWidth);
		return () => window.removeEventListener("resize", updateWidth);
	});
	const updateWidth = () => {
		setWidth(window.innerWidth);
	};

	//values the radius and circumference of the ring based on the width of the page
	const radius = width < 767 ? "35" : "70";
	const circumference = width < 767 ? "220" : "440";
	//length of the progress ring
	const percentage1 = circumference - (circumference * runtime[1]) / 100;
	const percentage2 = circumference - (circumference * memory[1]) / 100;

	return (
		<div className='rings'>
			{/* -------ring 1 -------*/}
			<div className='ring'>
				<div>
					<h2 className='ring__title'>Runtime</h2>
					<h2 className='ring__details'>
						This solution is faster than {runtime[1]}% of JavaScript online
						submissions for this challenge
					</h2>
				</div>
				<Ring
					radius={radius}
					percentage={percentage1}
					ringNum={"1"}
					data={runtime}
				/>
			</div>

			{/* ------ring 2 ------*/}
			<div className='ring'>
				<div>
					<h2 className='ring__title'>Memory</h2>
					<h2 className='ring__details'>
						This solution uses memory that is less than {memory[1]}% of JavaScript
						online submissions for this challenge
					</h2>
				</div>
				<Ring
					radius={radius}
					percentage={percentage2}
					ringNum={"2"}
					data={memory}
				/>
			</div>
		</div>
	);
};

export default CodeRing;
