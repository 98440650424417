import React from "react";
import WorkImages from "../WorkImages/WorkImages.jsx";
import demoIcon from "../../assets/icons/demo.svg";
import codeIcon from "../../assets/icons/code.svg";
import websiteIcon from "../../assets/icons/website.svg";
import "../WorkItem/WorkItem.scss";
import { v4 as uuidv4 } from "uuid";

const WorkItem = props => {
	const {
		title,
		description,
		openWork,
		setShowStack,
		tech,
		images,
		showStack,
		code,
		demo,
		wasSelected,
		app,
		i,
	} = props;

	return (
		<>
			<h3
				className='project__title'
				style={wasSelected ? { opacity: 0 } : { opacity: 1 }}
				onClick={() => {
					openWork(i);
					setShowStack(false);
					return;
				}}
			>
				{title}
			</h3>

			<div className='project-card'>
				<h1 className='project-card__title'>{title}</h1>
				{demo && !showStack && (
					<a href={demo} target='blank' className='project-card__link'>
						<img
							src={demoIcon}
							className='project-card__demo'
							alt='Click here to view the demo for the app'
						/>
					</a>
				)}
				{code && !showStack && (
					<a href={code} target='blank' className='project-card__link'>
						<img
							src={codeIcon}
							className='project-card__code'
							alt='Click here to view the code for the app'
						/>
					</a>
				)}
				{app && !showStack && (
					<a href={app} target='blank' className='project-card__link'>
						<img
							src={websiteIcon}
							className='project-card__code'
							alt='Click here to view the application'
						/>
					</a>
				)}
				<button
					className='project-content__close  button__showStack'
					onClick={() => {
						openWork("");
						setShowStack(true);
					}}
					style={wasSelected ? { opacity: 1 } : { opacity: 0 }}
				>
					back to list
				</button>
				<div
					className='project-content'
					style={wasSelected ? { opacity: 1 } : { opacity: 0 }}
				>
					<div className='project-content__info'>
						<p className='project-content__info-description'>{description}</p>
						<div className='project-content__info-stack'>
							{tech.map(i => {
								return (
									<p className='project-content__info-tech' key={uuidv4()}>
										{i}
									</p>
								);
							})}
						</div>
					</div>
					<div className='project-content__preview'>
						<WorkImages images={images} title={title} key={uuidv4()} />
					</div>
				</div>
			</div>
		</>
	);
};

export default WorkItem;
