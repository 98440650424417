import React from "react";
import "../Timeline/Timeline.scss";

const Timeline = props => {
	const { index, year, period, title, description, picture, institute } = props;

	return (
		<div className='event'>
			<h2 className='event__year'>{year}</h2>
			<div className={`event__box ${index % 2 !== 0 ? "event__box--1" : ""}`}>
				<div
					className={`event__details ${index % 2 !== 0 ? "event__details--1" : ""}`}
				>
					<h2
						className={`event__time-interval ${
							index % 2 !== 0 ? "event__time-interval--1" : ""
						}`}
					>
						{period}
					</h2>
					<h3 className={`event__title ${index % 2 !== 0 ? "event__title--1" : ""}`}>
						{title}
					</h3>
					<p className={`event__info ${index % 2 !== 0 ? "event__info--1" : ""}`}>
						{description}
					</p>
				</div>
				<img
					className={`event__picture ${index % 2 !== 0 ? "event__picture--1" : ""}`}
					src={picture}
					alt={`Logo of ${institute}`}
				/>
			</div>
		</div>
	);
};
export default Timeline;
