import React, { useState } from "react";
import "../Work/Work.scss";
import WorkStack from "../WorkStack/WorkStack.jsx";
import WorkFooter from "../WorkFooter/WorkFooter.jsx";

const Work = () => {
	const [selectedProject, setSelectedProject] = useState("");
	const [showStack, setShowStack] = useState(true);

	return (
		<section className={` ${showStack ? " work work--stack" : "work"}`}>
			<div className='work__header'>
				<h1
					className='work__title'
					style={
						showStack
							? {
									opacity: 1,
									textAlign: "center",
							  }
							: {
									opacity: 0,
									textAlign: "center",
									fontSize: "2rem",
							  }
					}
				>
					Projects & Challenges
				</h1>
			</div>
			<WorkStack
				selectedProject={selectedProject}
				showStack={showStack}
				// totalWork={totalWork}
				setSelectedProject={setSelectedProject}
				setShowStack={setShowStack}
			/>
			{/* a bar for the footer where the background-color is dependant if the stacks or showing or not */}
			<WorkFooter showStack={showStack} selectedProject={selectedProject} />
		</section>
	);
};

export default Work;
