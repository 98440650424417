import React from "react";
import "./WorkImages.scss";
import { v4 as uuidv4 } from "uuid";

const WorkImages = props => {
	const { images, title } = props;

	return images.map(i => {
		return (
			<img
				className='project-content__image'
				src={i}
				alt={`Screenshot of the ${title} app`}
				key={uuidv4()}
			/>
		);
	});
};
export default WorkImages;
