import React, { useState, useEffect } from "react";
import "../Techstack/Techstack.scss";
import { useViewportScroll, useTransform, motion } from "framer";
import { techlist } from "../Techstack/techlist.js";
import { v4 as uuidv4 } from "uuid";

const Techstack = () => {
	const [height, setHeight] = useState(window.innerHeight);
	useEffect(() => {
		window.addEventListener("resize", updateHeight);
		return () => window.removeEventListener("resize", updateHeight);
	});
	const updateHeight = () => {
		setHeight(window.innerHeight);
	};

	const { scrollYProgress } = useViewportScroll();
	const yPosTechStack = useTransform(
		scrollYProgress,
		[0, 0.5, 1],
		[height, 0.5 * height, 0],
	);
	const rotXTechStack = useTransform(
		scrollYProgress,
		[0, 0.5, 1],
		[-90, -45, 0],
	);
	const letterStyle = {
		width: "100%",
		height: "100%",
		position: "fixed",
		transformStyle: "preserve-3d",
		perspective: "1200px",
		transformOrigin: "center top",
	};

	return (
		<motion.div
			style={{
				...letterStyle,
				y: yPosTechStack,
				rotateX: rotXTechStack,
			}}
		>
			<div className='home__content'>
				<div className='tech-stack'>
					<h1 className='tech-stack__title'>My Tech Stack</h1>
					{techlist.map(list => {
						return (
							<div className='tech-stack__list' key={uuidv4()}>
								<h3 className='tech-stack__list-name'>{list.title}</h3>
								{list.tech.map(tech => {
									return (
										<img
											src={tech[0]}
											className='tech-stack__icon'
											alt={tech[1]}
											key={uuidv4()}
										/>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		</motion.div>
	);
};

export default Techstack;
