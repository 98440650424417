import React from "react";
import linkedInIcon from "../../assets/images/linkedin-logo.png";
import emailIcon from "../../assets/icons/email.png";
import githubIcon from "../../assets/icons/github.png";
import "../Footer/Footer.scss";
import { v4 as uuidv4 } from "uuid";

const Footer = () => {
	const connectMenu = [
		{ link: "https://www.linkedin.com/in/lovefababeir/", imgSrc: linkedInIcon },
		{ link: "mailto: love.fababeir@gmail.com", imgSrc: emailIcon },
		{ link: "https://github.com/lovefababeir", imgSrc: githubIcon },
	];

	return (
		<section className='footer'>
			<h4 className='footer__signature'>Love Fababeir</h4>
			<ul className='connect'>
				{connectMenu.map(social => {
					const { link, imgSrc } = social;
					return (
						<li className='connect__item' key={uuidv4()}>
							<a href={link} className='connect__link' target='blank'>
								<img src={imgSrc} className='connect__icon' alt='LinkedIn Icon' />
							</a>
						</li>
					);
				})}
			</ul>
		</section>
	);
};

export default Footer;
