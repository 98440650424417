import React, { useState } from "react";
import Typist from "react-typist";
import { ScrollTo } from "react-scroll-to";
import fastforward from "../../assets/icons/ff-arrows.svg";

const HeroLoveText = ({ setShowText, showText, height }) => {
	const [showButton, setShowButton] = useState(false);

	return (
		<div className='hero__about hero__about--2'>
			<div className='hero__about-box'>
				<h1>
					<Typist
						cursor={{ show: true, blink: true, element: "_", hideWhenDone: true }}
						avgTypingDelay={70}
						onTypingDone={() => {
							setTimeout(() => {
								setShowText("name");
							}, 1000);
						}}
						className='hero__title hero__title--1 '
					>
						<Typist.Delay ms={2500} />
						aka
					</Typist>
				</h1>
				{(showText === "name" || showText === "description") && (
					<h1>
						<Typist
							cursor={{
								show: true,
								blink: true,
								element: "_",
								hideWhenDone: true,
							}}
							avgTypingDelay={50}
							onTypingDone={() => {
								setTimeout(() => {
									setShowText("description");
								}, 1000);
							}}
							className='hero__title'
						>
							Love
						</Typist>
					</h1>
				)}
				{showText === "description" && (
					<h4 className='hero__info'>
						<Typist
							cursor={{
								show: true,
								blink: true,
								element: "_",
								hideWhenDone: true,
								hideWhenDoneDelay: 1000,
							}}
							avgTypingDelay={40}
							onTypingDone={() => {
								setTimeout(() => {
									setShowButton(true);
								}, 1500);
							}}
						>
							Adventurous. Nocturnal. Self-Aware. Outgoing. Mindful. Plays Volleyball.
							Foodie. Loves to Travel. Perceptive. City Girl. Loves the Countryside.
						</Typist>
					</h4>
				)}

				{showButton && (
					<>
						<ScrollTo>
							{({ scroll }) => (
								<img
									onClick={() =>
										setTimeout(() => scroll({ x: 0, y: height * 1.5, smooth: true }), 500)
									}
									className='hero__techstack-link'
									src={fastforward}
									alt='Click here to see the rest of the page'
								/>
							)}
						</ScrollTo>
					</>
				)}
			</div>
		</div>
	);
};

export default HeroLoveText;
