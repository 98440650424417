import watchify1 from "../../assets/images/watchify/watchify1.png";
import watchify2 from "../../assets/images/watchify/watchify2.png";
import watchify3 from "../../assets/images/watchify/watchify3.png";
import watchify4 from "../../assets/images/watchify/watchify4.png";
import watchify5 from "../../assets/images/watchify/watchify5.png";
import watchify6 from "../../assets/images/watchify/watchify6.png";
import watchify7 from "../../assets/images/watchify/watchify7.png";
import watchify8 from "../../assets/images/watchify/watchify8.png";
import watchify9 from "../../assets/images/watchify/watchify9.png";

import silo1 from "../../assets/images/silo/silo1.png";
import silo2 from "../../assets/images/silo/silo2.png";
import silo3 from "../../assets/images/silo/silo3.png";
import silo4 from "../../assets/images/silo/silo4.png";
import silo5 from "../../assets/images/silo/silo5.png";
import silo6 from "../../assets/images/silo/silo6.png";
import silo7 from "../../assets/images/silo/silo7.png";
import silo8 from "../../assets/images/silo/silo8.png";
import silo9 from "../../assets/images/silo/silo9.png";
import silo10 from "../../assets/images/silo/silo10.png";
import silo11 from "../../assets/images/silo/silo11.png";
import silo12 from "../../assets/images/silo/silo12.png";
import silo13 from "../../assets/images/silo/silo13.png";

import wisejo1 from "../../assets/images/wisejo/wisejoDesktop1.png";
import wisejo2 from "../../assets/images/wisejo/wisejoDesktop2.png";
import wisejo3 from "../../assets/images/wisejo/wisejoDesktop3.png";
import wisejo4 from "../../assets/images/wisejo/wisejoDesktop4.png";
import wisejo5 from "../../assets/images/wisejo/wisejoDesktop5.png";
import wisejo6 from "../../assets/images/wisejo/wisejoDesktop6.png";
import wisejo7 from "../../assets/images/wisejo/wisejoDesktop7.png";
import wisejo8 from "../../assets/images/wisejo/wisejoDesktop8.png";
import wisejo9 from "../../assets/images/wisejo/wisejoDesktop9.png";
import wisejo10 from "../../assets/images/wisejo/wisejoDesktop10.png";
import wisejo11 from "../../assets/images/wisejo/wisejoDesktop11.png";
import wisejo12 from "../../assets/images/wisejo/wisejoTablet1.png";
import wisejo13 from "../../assets/images/wisejo/wisejoTablet3.png";
import wisejo14 from "../../assets/images/wisejo/wisejoTablet4.png";
import wisejo15 from "../../assets/images/wisejo/wisejoTablet5.png";
import wisejo16 from "../../assets/images/wisejo/wisejoTablet6.png";
import wisejo17 from "../../assets/images/wisejo/wisejoTablet7.png";
import wisejo18 from "../../assets/images/wisejo/wisejoMobile1.png";
import wisejo19 from "../../assets/images/wisejo/wisejoMobile4.png";
import wisejo20 from "../../assets/images/wisejo/wisejoMobile8.png";
import wisejo21 from "../../assets/images/wisejo/wisejoMobile7.png";

import Zigzag from "../../assets/images/leetcode/Zigzag/zigzag.png";

import ZigzagEx1 from "../../assets/images/leetcode/Zigzag/zigzagEx1.png";
import maxAreaEx from "../../assets/images/leetcode/MaxArea/maxAreaEx.png";

import richard1 from "../../assets/images/richardfababeir/richardDesktop1.png";
import richard2 from "../../assets/images/richardfababeir/richardDesktop3.png";
import richard3 from "../../assets/images/richardfababeir/richardDesktop4.png";
import richard4 from "../../assets/images/richardfababeir/richardDesktop5.png";
import richard5 from "../../assets/images/richardfababeir/richardDesktop7.png";
import richard6 from "../../assets/images/richardfababeir/richardDesktop10.png";
import richard7 from "../../assets/images/richardfababeir/richardTablet2.png";
import richard8 from "../../assets/images/richardfababeir/richardTablet4.png";
import richard9 from "../../assets/images/richardfababeir/richardTablet5.png";
import richard10 from "../../assets/images/richardfababeir/richardTablet6.png";
import richard11 from "../../assets/images/richardfababeir/richardMobile2.png";
import richard12 from "../../assets/images/richardfababeir/richardMobile3.png";
import richard13 from "../../assets/images/richardfababeir/richardMobile4.png";
import richard14 from "../../assets/images/richardfababeir/richardMobile5.png";
import richard15 from "../../assets/images/richardfababeir/richardMobile6.png";
import richard16 from "../../assets/images/richardfababeir/richardMobile8.png";
import richard17 from "../../assets/images/richardfababeir/richardMobile9.png";
import richard18 from "../../assets/images/richardfababeir/richardMobile10.png";

import oneTribe1 from "../../assets/images/onetribe/onetribeDesktop1.png";
import oneTribe2 from "../../assets/images/onetribe/onetribeDesktop2.png";
import oneTribe3 from "../../assets/images/onetribe/onetribeDesktop3.png";
import oneTribe4 from "../../assets/images/onetribe/onetribeTablet1.png";
import oneTribe5 from "../../assets/images/onetribe/onetribeTablet2.png";
import oneTribe6 from "../../assets/images/onetribe/onetribeTablet3.png";
import oneTribe7 from "../../assets/images/onetribe/onetribeMobile1.png";
import oneTribe8 from "../../assets/images/onetribe/onetribeMobile2.png";
import oneTribe9 from "../../assets/images/onetribe/onetribeMobile3.png";
import oneTribe10 from "../../assets/images/onetribe/onetribeMobile4.png";

import { v4 as uuidv4 } from "uuid";

export const workList = [
	{
		title: "WiseJo",
		description:
			"Inspired by one of my Maths lessons for my grade 9 students, this app was created to help users make smart and practical decisions with their purchases. With the data scraped directly from the respective grocery stores using headless Chrome, WiseJo presents a comparison of up to date prices sorted by store and by capacity. WiseJo also offers advice on the best purchase based on unit rates and the location of the user. Using OCR from the Tesseract API to convert uploaded images to text, Wisejo helps users keep a record of their purchases and tracks expenses.",

		tech: [
			"React",
			"SCSS",
			"Node JS",
			"Express JS",
			"Firebase",
			"Microsoft Vision API",
			"Puppeteer",
			"Netlify",
			"Heroku",
		],
		code: "https://github.com/lovefababeir/WiseJo",
		demo:
			"https://www.linkedin.com/posts/lovefababeir_brainstation-learning-mathematics-activity-6728514639270866944-s1vu",
		link: "https://wisejo.netlify.app/",
		id: uuidv4(),
		type: "project",
		images: [
			wisejo1,
			wisejo2,
			wisejo3,
			wisejo4,
			wisejo5,
			wisejo6,
			wisejo7,
			wisejo8,
			wisejo9,
			wisejo10,
			wisejo11,
			wisejo12,
			wisejo13,
			wisejo14,
			wisejo15,
			wisejo16,
			wisejo17,
			wisejo18,
			wisejo19,
			wisejo20,
			wisejo21,
		],
	},
	{
		title: "Diorite Contracting",
		description:
			"The business website of Richard Fababeir who is a Real-Estate Agent and the President of Diorite Contracting. The website highlights his expertise, experience and services.",

		tech: [
			"Gatsby JS",
			"SCSS",
			"GraphQL",
			"Contentful",
			"Material-UI",
			"Netlify",
		],

		link: "https://richardfababeir.com",
		id: uuidv4(),
		type: "project",
		images: [
			richard1,
			richard2,
			richard3,
			richard4,
			richard5,
			richard6,
			richard7,
			richard8,
			richard9,
			richard10,
			richard11,
			richard12,
			richard13,
			richard14,
			richard15,
			richard16,
			richard17,
			richard18,
		],
	},
	{
		title: "#OneTribe",
		description:
			"A splash page completed for a tech challenge: Governments around the world are starting to petition to outlaw superpowers from being used. This splash page was created to support superheroes to show their importance to society and encourage the public to stand with all our heroes. ",

		tech: ["React", "SCSS", "Netlify"],

		link: "https://richardfababeir.com",
		id: uuidv4(),
		type: "project",
		images: [
			oneTribe1,
			oneTribe2,
			oneTribe3,
			oneTribe4,
			oneTribe5,
			oneTribe6,
			oneTribe7,
			oneTribe8,
			oneTribe9,
			oneTribe10,
		],
	},

	{
		title: "Silo",
		description:
			"A management system built to track the information of the location, manager and contact details of each warehouse, as well as the details of the items and their stock availability.  Users can create a profile of new warehouses and/or inventory and then view, update or delete the details and stock.  Built alongside two other developers this was completed in a week. Check out the reusable components List and ListItem used for the Warehouse and Inventory pages that I coded and that helped bring our team about 30% ahead of all other groups. Click on the icon next to the title to see the code.",
		tech: ["React", "SCSS", "Node JS", "Express JS", "Jira"],
		code: "https://github.com/lovefababeir/Silo",
		demo: "",
		id: uuidv4(),
		type: "project",
		images: [
			silo1,
			silo2,
			silo3,
			silo4,
			silo5,
			silo6,
			silo7,
			silo8,
			silo9,
			silo10,
			silo11,
			silo12,
			silo13,
		],
	},
	{
		title: "Watchify",
		description:
			"A prototype for a new video streaming platform.  Watchify is designed similarly to other streaming platforms where users can also upload their own content.  They can write comments on a video, post it in real-time and like a video. Click on the icon next to the title to see the code.",
		tech: ["React", "SCSS", "Node JS", "Express JS"],
		code: "https://github.com/lovefababeir/Watchify",
		demo: "",
		id: uuidv4(),
		type: "project",
		images: [
			watchify1,
			watchify2,
			watchify3,
			watchify4,
			watchify5,
			watchify6,
			watchify7,
			watchify8,
			watchify9,
		],
	},

	{
		title: "Zigzag Conversion",
		level: "Medium",
		description:
			"The string PAYPALISHIRING is written in a zigzag pattern on a given number of rows like shown below.  And then read line by line: PAHNAPLSIIGYIR. Write the code that will take a string and make this conversion given a number of rows.",
		examples: ZigzagEx1,
		link: "https://leetcode.com/problems/zigzag-conversion/",
		code: `//MY SOLUTION:\r\nvar convert = function (s, numRows) {\r\r\n\t\tlet answer = [];\r\r\n\t\tlet cycle = numRows === 1 ? 1 : numRows + numRows - 2;\r\n\t\tfor (let i = 0; i < numRows; i++) {\r\n\t\t\tanswer.push("");\r\n\t\t}\r\n\t\tfor (let k = 0; k < s.length; k++) {\r\n\t\t\tlet row = k % cycle < numRows ? k % cycle : cycle - (k % cycle);\r\n\t\t\tanswer.splice(row, 1, answer[row] + s[k]);\r\n\t\t}\r\n\t\treturn answer.join("");\r\n\t};`,
		id: uuidv4(),
		type: "LeetCode",
		images: [Zigzag],
		runtime: ["108ms", 78.36],
		memory: ["44.3MB", 45.07],
	},
	// {
	// 	title: "Longest Substring without Repeating Characters",
	// 	level: "Medium",
	// 	description:
	// 		"Given a string s, find the length of the longest substring without repeating characters.",
	// 	examples: longestSubstringEx1,
	// 	link:
	// 		"https://leetcode.com/problems/longest-substring-without-repeating-characters/",
	// 	code: `//MY SOLUTION:\r\nvar lengthOfLongestSubstring = function (s) {\r\n\tlet winner = "";\r\n\tlet tester = "";\r\n\r\n\tif (s.length === 1) {\r\n\t\twinner = s;\r\n\t} else {\r\n\t\tfor (var i = 0; i < s.length; i++) {\r\n\t\t\tif (tester.includes(s[i]) || i === s.length - 1) {\r\n\t\t\t\twinner = tester.length > winner.length ? tester : winner;\r\n\t\t\t\ttester = tester.slice(tester.indexOf(s[i]) + 1, tester.length);\r\n\t\t\t\ttester += s[i];\r\n\t\t\t} else {\r\n\t\t\t\ttester += s[i];\r\n\t\t\t}\r\n\t\t}\r\n\t\twinner = tester.length > winner.length ? tester : winner;\r\n\t}\r\n\r\n\treturn winner.length;\r\n};`,
	// 	id: uuidv4(),
	// 	type: "LeetCode",
	// 	images: [longestSubstring],
	// 	runtime: ["144ms", 43.02],
	// 	memory: ["45.9MB", 18.66],
	// },
	{
		title: "Container with the Most Water",
		level: "Medium",
		description:
			"Given n non-negative integers a1, a2, ..., an , where each represents a point at coordinate (i, ai). n vertical lines are drawn such that the two endpoints of the line i is at (i, ai) and (i, 0). Find two lines, which, together with the x-axis forms a container, such that the container contains the most water.",
		examples: maxAreaEx,
		link: "https://leetcode.com/problems/container-with-most-water/",
		code: `//MY SOLUTION:\r\tvar maxArea = height => {\r\tlet leftSide = 0;\r\tlet rightSide = height.length - 1;\r\tlet h;\r\tlet l;\r\tlet area = 0;\r\tlet areaMax = 0;\r\tfor (i = 0; i < height.length - 1; i++) {\r\t\tl = height.length - 1 - i;\r\t\tif (height[leftSide] < height[rightSide]) {\r\t\t\th = height[leftSide];\r\t\t\tleftSide++;\r\t\t} else {\r\t\t\th = height[rightSide];\r\t\t\trightSide--;\r\t\t}\r\t\tarea = h * l;\r\t\tareaMax = Math.max(area, areaMax);\r\t}\r\treturn areaMax;\r};`,
		id: uuidv4(),
		type: "LeetCode",
		// images: [longestSubstring],
		runtime: ["84ms", 89.79],
		memory: ["48MB", 38.65],
	},
	{
		title: "Reverse Integer",
		level: "Easy",
		description:
			"Given a signed 32-bit integer x, return x with its digits reversed. If reversing x causes the value to go outside the signed 32-bit integer range [-2^32, 2^31 - 1], then return 0",
		// examples: longestSubstringEx1,
		link: "https://leetcode.com/problems/reverse-integer/",
		code: `//MY SOLUTION:\r\tvar reverse = function (x) {\r\tconst testRange = num => {\r\t\treturn -Math.pow(2, 31) <= num && num <= Math.pow(2, 31) - 1;\r\t};\r\r\tif (testRange(x) && typeof x === "number") {\r\t\tlet sign = x < 0 ? -1 : 1;\r\r\t\tlet revX = x.toString().split("").reverse().join("");\r\t\trevNum = sign * parseInt(revX);\r\t\treturn testRange(revNum) ? revNum : 0;\r\t} else {\r\t\treturn 0;\r\t}\r};`,
		id: uuidv4(),
		type: "LeetCode",
		// images: [longestSubstring],
		runtime: ["96ms", 75.57],
		memory: ["40.9MB", 8.98],
	},
];
