import React, { useEffect, useState } from "react";
import "../Hero/Hero.scss";
import { useViewportScroll, motion, useTransform } from "framer-motion";
import HeroContent from "./HeroContent";

const Hero = () => {
	//code to take into account the browsers menu bar and address bar for calculating the web pages' height
	const [height, setHeight] = useState(window.innerHeight);
	useEffect(() => {
		window.addEventListener("resize", updateHeight);
		return () => window.removeEventListener("resize", updateHeight);
	});
	const updateHeight = () => {
		setHeight(window.innerHeight);
	};

	//scroll animations
	const { scrollYProgress } = useViewportScroll();
	const yPosHero = useTransform(
		scrollYProgress,
		[0, 0.5, 1],
		[0, -0.5 * height, -height],
	);
	const rotXHero = useTransform(scrollYProgress, [0, 0.5, 1], [0, 45, 90]);
	const heroStyle = {
		width: "100%",
		height: "100%",
		position: "fixed",
		transformStyle: "preserve-3d",
		perspective: "1200px",
		transformOrigin: "center bottom",
	};

	return (
		<motion.div
			style={{
				...heroStyle,
				y: yPosHero,
				rotateX: rotXHero,
			}}
		>
			<HeroContent height={height} />
		</motion.div>
	);
};

export default Hero;
