import york from "../../assets/images/yorku-logo.jpg";
import brainstation from "../../assets/images/brainstation2.png";
import willowwood from "../../assets/images/willowwood.jpg";
import juno from "../../assets/images/junocollege2.png";
import otu from "../../assets/images/otu.jpg";
import sarum from "../../assets/images/sarum.png";
import watchncode from "../../assets/images/watchncode.jpeg";

export const eventsList = [
	{
		year: "2007",
		period: "Sept 2007 - June 2011",
		company: "York University",
		title: "BSc Mathematics Specialized Honours",
		type: "education",
		description:
			"My very first experiences with coding were elective computer sciences courses for University on Visual Basic and Excel.  I unexpectedly enjoyed coding and surprisingly excelled in it (top mark in the class) but unfortunately did not know what I could do with it.",
		tech: ["Visual Basic", "Excel", "MatLab"],
		image: york,
	},
	{
		year: "2011",
		period: "Sept 2011 - June 2012",
		company: "Ontario Technology University",
		title: "BEd Intermediate/Senior",
		type: "education",
		description:
			"So I continued to pursue a career in Education. Growing up, culturally, only the very popular careers were encouraged like becoming a doctor, a lawyer, a teacher, a nurse, an accountant or an engineer.  Out of those, teaching was all that I knew and felt passionate about.",
		tech: ["SmartBoards", "Wix"],
		image: otu,
	},
	{
		year: "2012",
		period: "Sept 2012 - Aug 2013",
		company: "Sarum Academy",
		title: "High School Mathematics Teacher and School Secretary",
		type: "work",
		description:
			"In moving to England and getting my first job as a teacher and then as a secretary, I got to experience what it was like to work in an office.  I saw my tech skills once again emerge as I explored ways to improve efficiency in my functionality on the team and as I learnt to create forms on Word, export the database onto Excel and harness the powers of mail-merging.",
		tech: ["Word", "Excel"],
		image: sarum,
	},
	{
		year: "2014",
		period: "Feb 2014 - June 2019",
		company: "WillowWood School",
		title: "High School Mathematics Teacher",
		type: "work",
		description:
			"After 6 years of teaching and 5 years of complete dedication to my job in Toronto, I felt fulfilled in this career but could not help feeling a stunt in my growth.  In the years of advocating for lifelong learning, I earned more and more to learn more for myself and to be in a space of more collaboration and growth.  So I took a big leap of faith and left my job.",
		tech: [
			"Excel",
			"Word",
			"PowerPoint",
			"SmartBoards",
			"BlackBaud",
			"Geogebra",
			"Desmos",
		],
		image: willowwood,
	},
	{
		year: "2019",
		period: "Sept 2020 - Dec 2020",
		company: "Watch and Code",
		title: "JavaScript & HTML",
		type: "education",
		description:
			"Throughout the years as a teacher, I've always considered becoming a developer.  While unemployed I took the time to learn JavaScript and HTML, creating todo lists using online resources and I enjoyed it.",
		tech: ["JavaScript", "HTML"],
		image: watchncode,
	},
	{
		year: "2020",
		period: "Feb 2020",
		company: "Juno College",
		title: "Introduction to Web Development",
		type: "education",
		description:
			"To truly consider a career shift, I invested in an intro course at Juno College and learnt to build a static website. In taking this course I learnt so much about myself. I realized that even as a teacher, I've always been a developer.  I've always had great attention to detail, took every opportunity to learn something new and have always been resourceful. I was very particular with the layout of my handouts and how they facilitated the thought processes and anticipated the responses of my students.",
		tech: ["HTML", "CSS", "SCSS", "Flex"],
		image: juno,
	},
	{
		year: "2020",
		period: "Mar 2020 - June 2020",
		company: "WillowWood School",
		title: "High School Mathematics Teacher",
		type: "work",
		description:
			"I realized this was it! I was locked into this journey of becoming a Web Developer.  But in the months before classes started, I went back to my old workplace to help cover for head of the Mathematics department.",
		tech: [
			"Excel",
			"Word",
			"PowerPoint",
			"SmartBoards",
			"BlackBaud",
			"Geogebra",
			"Desmos",
		],
		image: willowwood,
	},
	{
		year: "2020",
		period: "June 2020 - Sept 2020",
		company: "BrainStation",
		title: "Web Development Diploma Program",
		type: "education",
		description:
			"Bootcamp was such an exhilarating experience of 12 weeks of intense learning.  It was amazing to see how much I achieved in the process and how I was able to develop my own app in the end.  I'm so excited to apply the skills I've gained, to grow more and to explore my potential in my new career as Full-stack Web Developer.",
		tech: [
			"HTML",
			"CSS",
			"SCSS",
			"React",
			"Node JS",
			"Express JS",
			"MySQL",
			"Git",
			"Github",
			"Jira",
			"Tesseract",
			"",
		],
		image: brainstation,
	},
];
