import React from "react";
import "./App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Journey from "./components/Journey/Journey";
import Work from "./components/Work/Work.jsx";
import Footer from "./components/Footer/Footer.jsx";
import Connect from "./components/Connect/Connect";

function App() {
	window.addEventListener("resize", () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	});

	return (
		<div className='App'>
			<BrowserRouter>
				<Header />
				<main className='content'>
					<Switch>
						<Route path='/' exact component={Home} />
						<Route path='/journey' component={Journey} />
						<Route path='/work' component={Work} />
						<Route path='/connect' component={Connect} />
					</Switch>
				</main>
				<Footer />
			</BrowserRouter>
		</div>
	);
}

export default App;
