import React from "react";
import "../ConnectForm/ConnectForm.scss";
import Typist from "react-typist";

export default class MyForm extends React.Component {
	constructor(props) {
		super(props);
		this.submitForm = this.submitForm.bind(this);
		this.state = {
			status: "",
		};
	}

	render() {
		const { status } = this.state;
		return (
			<form
				onSubmit={this.submitForm}
				action='https://formspree.io/f/mwkwgvqn'
				method='POST'
				className='connect-form'
			>
				{status !== "SUCCESS" && (
					<>
						<Typist
							cursor={{
								show: true,
								blink: true,
								element: "_",
								hideWhenDone: false,
							}}
							avgTypingDelay={70}
							className='connect-form__title'
						>
							<Typist.Delay ms={1000} />
							Thanks for dropping by.
							<Typist.Backspace count={23} delay={2000} />
							<Typist.Delay ms={1000} />
							Let's connect!
						</Typist>
						<input
							type='text'
							name='name'
							className='connect-form__textbox'
							placeholder='Name'
						/>
						<input
							type='email'
							name='email'
							className='connect-form__textbox'
							placeholder='Email'
						/>
						<textarea
							name='message'
							className='connect-form__textbox connect-form__textbox--2'
							placeholder='Message'
						/>
					</>
				)}
				{status === "SUCCESS" ? (
					<>
						<p className='connect-form__success-msg'>
							SUCCESS! <br />
							<Typist
								cursor={{
									show: true,
									blink: true,
									element: "_",
									hideWhenDone: true,
									hideWhenDoneDelay: 1000,
								}}
								avgTypingDelay={90}
								className='connect-form__success-msg'
							>
								<Typist.Delay ms={2000} />
								Thanks again for dropping by. I look forward to connecting with you.
							</Typist>
						</p>
					</>
				) : (
					<button className='connect-form__button'>Submit</button>
				)}
				{status === "ERROR" && (
					<p className='connect-form__error-msg'>
						Ooops! There was an error. Refresh the page and try again.
					</p>
				)}
			</form>
		);
	}

	submitForm(ev) {
		ev.preventDefault();
		const form = ev.target;
		const data = new FormData(form);
		const xhr = new XMLHttpRequest();
		xhr.open(form.method, form.action);
		xhr.setRequestHeader("Accept", "application/json");
		xhr.onreadystatechange = () => {
			if (xhr.readyState !== XMLHttpRequest.DONE) return;
			if (xhr.status === 200) {
				form.reset();
				this.setState({ status: "SUCCESS" });
			} else {
				this.setState({ status: "ERROR" });
			}
		};
		xhr.send(data);
	}
}
