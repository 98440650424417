import reactjs from "../../assets/icons/techstack/react.png";
import sass from "../../assets/icons/techstack/sass.png";
import cssHtmlJs from "../../assets/icons/techstack/css-html-js.png";
import node from "../../assets/icons/techstack/node.png";
import express from "../../assets/icons/techstack/expressjs-icon.png";
import Puppeteer from "../../assets/icons/techstack/puppeteer.png";
import Tesseract from "../../assets/icons/techstack/tesseractocr.png";
// import rapidapi from "../../assets/icons/techstack/rapidapi.png";
import mongodb from "../../assets/icons/techstack/mongodb.png";
import mysql from "../../assets/icons/techstack/mysql-icon.png";
import git from "../../assets/icons/techstack/git-icon.png";
import github from "../../assets/icons/techstack/github.png";
// import npm from "../../assets/icons/techstack/npm.png";
import jira from "../../assets/icons/techstack/jira.png";
import netlify from "../../assets/icons/techstack/netlify.png";
import graphql from "../../assets/icons/techstack/graphql.png";
import contentful from "../../assets/icons/techstack/contentful.png";
import gatsby from "../../assets/icons/techstack/gatsbyjs.png";
import azure from "../../assets/icons/techstack/azure.png";
import firebase from "../../assets/icons/techstack/firebase.png";
import heroku from "../../assets/icons/techstack/heroku.png";
import materialui from "../../assets/icons/techstack/materialui.png";

export const techlist = [
	{
		title: "FrontEnd",
		tech: [
			[reactjs, "React JS"],
			[gatsby, "Gastby JS"],
			[sass, "Sass"],
			[cssHtmlJs, "HTML 5, CSS 3 and JavaScript"],
		],
	},
	{
		title: "BackEnd",
		tech: [
			[node, "Node JS"],
			[express, "Express JS"],
		],
	},
	{
		title: "Database",
		tech: [
			[mongodb, "Mongo DB"],
			[mysql, "My SQL"],
		],
	},
	{
		title: "DevTools",
		tech: [
			[git, "git for version control"],
			[github, "github"],
			[netlify, "Netlify for deploying"],
			[heroku, "Heroku"],
			[firebase, "Firebase"],
			[jira, "Jira software for product development tracking"],
			[Tesseract, "Tesseract an OCR API"],
			[Puppeteer, "Puppeteer a Web Scraping API"],
			[graphql, "Graph QL"],
			[contentful, "Contentful, a headless content management system"],
			[azure, "Microsoft Azure"],
			[materialui, "Material UI"],
		],
	},
];
