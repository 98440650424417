import React, { Component } from "react";
import "../Leetcode/Leetcode.scss";
import Prism from "prismjs";
// import "../Leetcode/prism.css";
import "../Leetcode/Leetcode.scss";

class Leetcode extends Component {
	componentDidMount() {
		Prism.highlightAll();
	}

	render() {
		return (
			<div className='leetcode' style={{ paddingBottom: "1.5rem" }}>
				<pre>
					<code className='language-javascript'>{this.props.code}</code>
				</pre>
			</div>
		);
	}
}

export default Leetcode;
