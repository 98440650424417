import React from "react";
import "./WorkFooter.scss";

const WorkFooter = ({ showStack, selectedProject }) => {
	return (
		<div
			className='work__footerBG'
			style={
				showStack
					? {
							backgroundColor: "rgb(107, 126, 107)",
							boxShadow: "2px -2px 5px rgba(46, 53, 46, 0.808)",
					  }
					: selectedProject % 2
					? {
							backgroundColor: "rgb(107, 126, 107)",
							boxShadow: "2px -2px 5px rgba(46, 53, 46, 0.808)",
					  }
					: {
							backgroundColor: "rgb(85, 97, 85)",
							boxShadow: "2px -2px 5px rgba(46, 53, 46, 0.808)",
					  }
			}
		></div>
	);
};

export default WorkFooter;
