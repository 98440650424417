import React from "react";
import "../Connect/Connect.scss";
import connectPhoto from "../../assets/images/connectpagephoto.JPG";
import ConnectForm from "../ConnectForm/ConnectForm.jsx";

const Connect = () => {
	return (
		<section className='connect-page'>
			<div className='connect-page__bg'>
				<div className='connect-page__bg connect-page__bg--dark'>
					<div className='connect-page__bg connect-page__bg--white'></div>
				</div>
			</div>
			<div className='connect-page__content'>
				<div className='connect-page__text-box'>
					<ConnectForm />
				</div>
				<img
					src={connectPhoto}
					className='connect-page__photo'
					alt='Love outdoors during Covid'
				/>
			</div>
		</section>
	);
};

export default Connect;
